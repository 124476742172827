import React from 'react';
import './App.scss';
import {CarComponent} from "./components/car/car.component";

function App() {
  return (
    <div className="App">
      <h1>Huize High Frontend</h1>
      <CarComponent></CarComponent>
    </div>
  );
}

export default App;
