import React from "react";
import './car.scoped.scss';

export class CarComponent extends React.Component {
    render() {
        return (
            <div className='text_color'>
                <h1>I am a car</h1>
            </div>
        )
    }
}
